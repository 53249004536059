module.exports = [{
      plugin: require('./node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-79553073-1","head":true,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('./node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/style/typography"},
    },{
      plugin: require('./node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/toure-icon.png"},
    },{
      plugin: require('./gatsby-browser.js'),
      options: {"plugins":[]},
    }]
